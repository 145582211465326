import React from "react";
import {
    Image
} from "react-bootstrap";
import Marquee from "react-fast-marquee";

import Partner1 from "../../assets/images/partner/apec.png";
import Partner2 from "../../assets/images/partner/astra-startup.png";
import Partner3 from "../../assets/images/partner/atsira.png";
import Partner4 from "../../assets/images/partner/catalyzon.png";
import Partner5 from "../../assets/images/partner/createla-impact.png";
import Partner6 from "../../assets/images/partner/germancoorp.png";
import Partner7 from "../../assets/images/partner/insfre.png";
import Partner8 from "../../assets/images/partner/pertamina.png";
import Partner9 from "../../assets/images/partner/sarasa.png";
import Partner10 from "../../assets/images/partner/sejutacita.png";
import Partner11 from "../../assets/images/partner/sre.png";
import Partner12 from "../../assets/images/partner/kementrian.png";
import Partner13 from "../../assets/images/partner/kabarbuana.png";
import Partner14 from "../../assets/images/partner/tribunews.png";
import Partner15 from "../../assets/images/partner/unknown2.png";
import Partner16 from "../../assets/images/partner/kompasiana.png";
import Partner17 from "../../assets/images/partner/djarum.png";
import Partner18 from "../../assets/images/partner/brin.png";
import Partner19 from "../../assets/images/partner/pemkot.png";
import Partner20 from "../../assets/images/partner/pesantren.png";
import Partner21 from "../../assets/images/partner/shedisrupts.png";
import Partner22 from "../../assets/images/partner/tani.png";
import Partner23 from "../../assets/images/partner/itb.png";
import Partner24 from "../../assets/images/partner/ipkani.png";
import Partner25 from "../../assets/images/partner/inventify.png";
import Partner26 from "../../assets/images/partner/et.png";
import Partner27 from "../../assets/images/partner/gsma.png";
import Partner28 from "../../assets/images/partner/glints.png";
import Partner29 from "../../assets/images/partner/unilever.png";
import Partner30 from "../../assets/images/partner/unknown3.png";
import Partner31 from "../../assets/images/partner/bina-insani.png";
import Partner32 from "../../assets/images/partner/suarabaru.png";
import Partner33 from "../../assets/images/partner/antara.png";

import "../../assets/css/style.css";

const PartnerClientContent = () => {

    return (

        <Marquee
            speed={50}
            gradient={true}
        >
            <div className="logo">
                <Image src={Partner1} loading="lazy"/>
                <Image src={Partner2} loading="lazy"/>
                <Image src={Partner3} loading="lazy"/>
                <Image src={Partner4} loading="lazy"/>
                <Image src={Partner5} loading="lazy"/>
                <Image src={Partner6} loading="lazy"/>
                <Image src={Partner7} loading="lazy"/>
                <Image src={Partner8} loading="lazy"/>
                <Image src={Partner9} loading="lazy"/>
                <Image src={Partner10} loading="lazy"/>
                <Image src={Partner11} loading="lazy"/>
                <Image src={Partner12} loading="lazy"/>
                <Image src={Partner13} loading="lazy"/>
                <Image src={Partner14} loading="lazy"/>
                <Image src={Partner15} loading="lazy"/>
                <Image src={Partner16} loading="lazy"/>
                <Image src={Partner17} loading="lazy"/>
                <Image src={Partner18} loading="lazy"/>
                <Image src={Partner19} loading="lazy"/>
                <Image src={Partner20} loading="lazy"/>
                <Image src={Partner21} loading="lazy"/>
                <Image src={Partner22} loading="lazy"/>
                <Image src={Partner23} loading="lazy"/>
                <Image src={Partner24} loading="lazy"/>
                <Image src={Partner25} loading="lazy"/>
                <Image src={Partner26} loading="lazy"/>
                <Image src={Partner27} loading="lazy"/>
                <Image src={Partner28} loading="lazy"/>
                <Image src={Partner29} loading="lazy"/>
                <Image src={Partner30} loading="lazy"/>
                <Image src={Partner31} loading="lazy"/>
                <Image src={Partner32} loading="lazy"/>
                <Image src={Partner33} loading="lazy"/>
            </div>
        </Marquee>

    );

};

export default PartnerClientContent;