import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import StudentsIconImage from "../../assets/images/icons/students-icon-main.png";
import StrategyIconImage from "../../assets/images/icons/strategy-icon-main.png";
import ArrowIconImage from "../../assets/images/icons/arrow-icon-main.svg";

import MainOneImage from "../../assets/images/general/main-one.png";
import MainTwoImage from "../../assets/images/general/main-two.png";

import ButtonFillWhite from "../button/ButtonFillWhite";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const MainContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#value-content';
        } else {
            document.getElementById('value-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <Container>
            <Row className="main-head-one" data-aos="fade-up">
                <Col xs={12} xl={8} data-aos="fade-up">
                    <h1>
                        <span>Exalter Students</span>, platform pembelajar untuk berkarya, berprestasi, dan meraih beasiswa
                    </h1>
                </Col>
                <Col xs={12} xl={4} data-aos="fade-up">
                    <div className="wrapper d-flex">
                        <div className="px-4 d-flex gap-3 align-items-center">
                            <Image className="img" src={StudentsIconImage} />
                            <div>
                                <h5>175+</h5>
                                <p>Students yang berdedikasi</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <p style={{ fontSize: '18px' }}>Exalter Students memberdayakan siswa dalam akademik, karier, kepemimpinan, dan pertumbuhan pribadi dengan sumber daya dan dukungan untuk sukses.</p>
                    </div>
                </Col>
            </Row>
            <Row className="main-head-two">
                <Col xs={12} xl={8}>
                    <div className="wrapper-one" style={{ height: window.innerWidth < 576 ? '200px' : '283px', width: '100%' }} data-aos="fade-up">
                        <Image className="img" src={MainOneImage} />
                    </div>
                    <div className={window.innerWidth < 576 ? "gap-4 mt-4" : "d-flex gap-4 mt-4"}>
                        <div className="wrapper-two px-4 py-3" style={{ height: '221px', width: '100%', background: '#344F97', borderRadius: '8px' }} data-aos="fade-up">
                            <div className="img-one d-flex align-items-center justify-content-center">
                                <Image src={StrategyIconImage} style={{height: window.innerWidth < 576 ? '20px' : null}}/>
                            </div>
                            <h5>Strategi Optimal Kerjasama Tim</h5>
                            <div className="d-flex mt-2">
                                <Col xs={9} xl={9}>
                                    <p>Mendukung kerja sama tim dan kemitraan yang kuat untuk mencapai tujuan bersama secara efektif.</p>
                                </Col>
                                <Col xs={3} xl={3} className="d-flex justify-content-end">
                                    <div className="img-two d-flex align-items-center justify-content-center align-self-end">
                                        <Image src={ArrowIconImage} style={{height: window.innerWidth < 576 ? '15px' : null}}/>
                                    </div>
                                </Col>
                            </div>
                        </div>
                        <div className="wrapper-three px-4 py-2" style={{ height: '221px', width: window.innerWidth < 576 ? '100%' : '435px', background: '#F4F5F7', borderRadius: '8px', marginTop: window.innerWidth < 576 ? '20px' : null }} data-aos="fade-up">
                            <h5>500+</h5>
                            <h6>Prestasi</h6>
                            <p>Menyoroti keunggulan siswa dalam akademik, kepemimpinan, dan komunitas.</p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={4} data-aos="fade-up">
                    <div className="wrapper-four" style={{ height: window.innerWidth < 576 ? '350px' : '528px', width: '100%', marginTop: window.innerWidth < 576 ? '20px' : null }} data-aos="fade-up">
                        <Image className="img" src={MainTwoImage} />
                    </div>
                    <div style={{ position: 'absolute', top: window.innerWidth < 576 ? '35px' : '20px', right: '25px' }}>
                        <ButtonFillWhite
                            onClick={handleAboutUsClick}
                            buttonName="Pelajari"
                            widthCustom={window.innerWidth < 576 ? '140px' : '155px'}
                        />
                    </div>
                </Col>
            </Row>
        </Container>

    );

};

export default MainContent;