import React from "react";
import {
    MapContainer,
    TileLayer,
    Popup,
    Marker
} from "react-leaflet";
import L from 'leaflet';
import MarkerIcon from "../../assets/images/icons/location.svg";

const MapWrapped = ({ centerCoordinates }) => {

    const getCustomIcon = () => {
        return L.icon({
            iconUrl: MarkerIcon,
            iconSize: [20, 25],
            iconAnchor: [10, 25],
            popupAnchor: [0, -15]
        });
    };

    return (

        <MapContainer
            center={centerCoordinates}
            zoom={10}
            style={{
                marginTop: '20px',
                width: `${window.innerWidth < 576 ? "100%" : "320px"}`,
                height: "200px",
                borderRadius: "20px"
            }}
        >
            <TileLayer
                url="https://api.maptiler.com/maps/topo-v2/256/{z}/{x}/{y}.png?key=aA11V8lEYGnQr9zR8OgO"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={centerCoordinates} icon={getCustomIcon()}>
                <Popup>
                    Lokasi Exalter Students!
                </Popup>
            </Marker>
        </MapContainer>

    );

};

export default MapWrapped;