import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    Container,
    Nav,
    Navbar,
    Image,
} from "react-bootstrap";

import ButtonFill from "../button/ButtonFill";

import LogoExalterStudents from "../../assets/images/general/logo-1.png";

import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const NavbarComponent = () => {

    const [scrolled, setScrolled] = useState(false);
    const [activeLink, setActiveLink] = useState('/');

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 20;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled)
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [scrolled]);

    useEffect(() => {
        setActiveLink(window.location.pathname);
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#value-content';
        } else {
            document.getElementById('value-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleServiceClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#service-content';
        } else {
            document.getElementById('service-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handlePartnerClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#partner-content';
        } else {
            document.getElementById('partner-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleMediaClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#media-content';
        } else {
            document.getElementById('media-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleContactClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#contact-content';
        } else {
            document.getElementById('contact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <Navbar className={scrolled ? "navbar-scrolled" : "navbar"} expand="lg" fixed="top">
            <Container style={{ padding: window.innerWidth < 576 ? '0' : null }}>
                <Navbar.Brand className="nav-brand" href="#">
                    <Image src={LogoExalterStudents} alt="InventifyCenterLogoImage" style={{ width: window.innerWidth < 576 ? '42px' : '62px' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link onClick={() => window.location.href = '/'} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/' ? 'active' : ''}`}>Beranda</Nav.Link>
                        <Nav.Link onClick={handleAboutUsClick} className={` ${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#value-content' ? 'active' : ''}`} >Tentang Kami</Nav.Link>
                        <Nav.Link onClick={handleServiceClick} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#service-content' ? 'active' : ''}`}>Servis</Nav.Link>
                        <Nav.Link onClick={handlePartnerClick} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#partner-content' ? 'active' : ''}`}>Partner</Nav.Link>
                        <Nav.Link onClick={() => window.location.href = '/events'} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/events' ? 'active' : ''}`}>Events</Nav.Link>
                        <Nav.Link onClick={handleMediaClick} className={` ${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#media-content' ? 'active' : ''}`}>Media</Nav.Link>
                    </Nav>
                    <div className="d-flex nav-btn">
                        <ButtonFill
                            onClick={handleContactClick}
                            buttonName="Hubungi Kami"
                            widthCustom="155px"
                        />
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );

};

export default NavbarComponent;