import React, { useState } from "react";
import {
    Button
} from "react-bootstrap";

const ButtonFill2 = ({ buttonName, widthCustom, paddingCustom, onClick, heightCustom }) => {

    const [isHovered, setIsHovered] = useState(false);

    return (

        <Button
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={
                {
                    backgroundColor: isHovered ? '#FFFFFF' : '#344F97', 
                    border: isHovered ? '1px solid #FFFFFF' :'1px solid #344F97', 
                    borderRadius: '8px', 
                    padding: paddingCustom,
                    cursor: 'pointer',
                    width: widthCustom,
                    fontSize: window.innerWidth < 576 ? '16px' : '18px',
                    fontWeight: '600',
                    color: isHovered ? '#344F97' : '#FFFFFF',
                    transition: '0.3s all ease-in-out',
                    height: heightCustom
                }
            }
        >
            {buttonName}
        </Button>

    );

};

export default ButtonFill2;