import React from "react";
import HomeLayout from "../../layouts/home/HomeLayout";
import MainContent from "../../components/home/MainContent";
import ValueContent from "../../components/home/ValueContent";
import VisionMissionContent from "../../components/home/VisionMission";
import ServiceContent from "../../components/home/ServiceContent";
import ImpactContent from "../../components/home/ImpactContent";
import PartnerContent from "../../components/home/PartnerContent";
import MediaContent from "../../components/home/MediaContent";
import ContactContent from "../../components/home/ContactContent";

import "../../assets/css/style.css";

const Home = () => {

    return (

        <HomeLayout>
            <div id="main-content">
                <MainContent/>
            </div>
            <div id="value-content">
                <ValueContent/>
            </div>
            <div id="vision-mission-content">
                <VisionMissionContent/>
            </div>
            <div id="service-content">
                <ServiceContent/>
            </div>
            <div id="impact-content">
                <ImpactContent/>
            </div>
            <div id="partner-content">
                <PartnerContent/>
            </div>
            <div id="media-content">
                <MediaContent/>
            </div>
            <div id="contact-content">
                <ContactContent/>
            </div>
        </HomeLayout>

    );

};

export default Home;