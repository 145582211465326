import Event1Image from "../images/events/event1.jpg";
import Event2Image from "../images/events/event2.jpg";
import Event3Image from "../images/events/event3.jpg";
import Event4Image from "../images/events/event4.jpg";
import Event5Image from "../images/events/event5.jpg";
import Event6Image from "../images/events/event6.jpg";
import Event7Image from "../images/events/event7.jpg";
import Event8Image from "../images/events/event8.jpg";
import Event9Image from "../images/events/events9.jpg";

export const EventData = {
    "type": "Event",
    "Collections": [
        {
            type: "Event",
            id: "1",
            properties:
            {
                title: "World Youth Researchers Conference 2024",
                date: "10 September 2024",
                image: Event1Image,
                link: "https://www.instagram.com/p/C-dGqcHStDQ/"
            }
        },
        {
            type: "Event",
            id: "2",
            properties:
            {
                title: "GLOBAL YOUTH INNOVATORS COMPETITION (GYIC) 2024⁣",
                date: "10 Juni 2024",
                image: Event2Image,
                link: "https://www.instagram.com/p/C7LIjyeLX03/"
            }
        },
        {
            type: "Event",
            id: "3",
            properties:
            {
                title: "NATIONAL CONFERENCE ON DIGITAL HARMONY",
                date: "24 Februari 2024",
                image: Event3Image,
                link: "https://www.instagram.com/p/C3ezwIhrfy_/"
            }
        },
        {
            type: "Event",
            id: "4",
            properties:
            {
                title: "Accelerating research idea towards a better Indonesia for 2030",
                date: "4 Februari 2024",
                image: Event4Image,
                link: "https://www.instagram.com/p/C2zl-4ov1YY/"
            }
        },
        {
            type: "Event",
            id: "5",
            properties:
            {
                title: "Crafted Brilliance: Mastering the Art of Scientific Writing for Impactful Research.",
                date: "4 Februari 2024",
                image: Event5Image,
                link: "https://www.instagram.com/p/C2kXjO5LK0U/?img_index=2"
            }
        },
        {
            type: "Event",
            id: "6",
            properties:
            {
                title: "Explore, Expand, Excel: Unleashing Creative Potential for Scientific Breakthroughs.",
                date: "3 Februari 2024",
                image: Event6Image,
                link: "https://www.instagram.com/p/C2kXjO5LK0U/?img_index=1"
            }
        },
        {
            type: "Event",
            id: "7",
            properties:
            {
                title: "Crafted Brilliance: Mastering the Art of Scientific Writing for Impactful Research.",
                date: "28 Januari 2024",
                image: Event7Image,
                link: "https://www.instagram.com/p/C2kXHL0rycA/?img_index=2"
            }
        },
        {
            type: "Event",
            id: "8",
            properties:
            {
                title: "Explore, Expand, Excel: Unleashing Creative Potential for Scientific Breakthroughs.",
                date: "27 Januari 2024",
                image: Event8Image,
                link: "https://www.instagram.com/p/C2kXHL0rycA/?img_index=1"
            }
        },
        {
            type: "Event",
            id: "9",
            properties:
            {
                title: "The Secret of Being Scholarship Awardee: the Key for Your Bright Future",
                date: "4 Desember 2021",
                image: Event9Image,
                link: "https://www.instagram.com/p/CW-jZ2Tv_QW/"
            }
        },
    ]
};