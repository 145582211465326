import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import { ServiceData } from "../../assets/js/serviceData";

import ServiceImage from "../../assets/images/general/service.png";

import ButtonFill2 from "../button/ButtonFill2";
import ButtonFillWhite2 from "../button/ButtonFillWhite2";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";


const ServiceContent = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleValueClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#value-content';
        } else {
            document.getElementById('value-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleImpactClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#impact-content';
        } else {
            document.getElementById('impact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5} className="d-flex flex-column service-title" data-aos="fade-up">
                    <div>
                        <h1>Mendorong Pertumbuhan dan Pembelajaran lewat Program Transformatif</h1>
                    </div>
                    <div className="d-flex flex-column gap-4 mt-4">
                        <div className="wrapper" style={{ height: window.innerWidth < 576 ? '350px' : '390px', width: '100%' }}>
                            <Image src={ServiceImage} style={{ height: '100%', width: '100%' }}/>
                        </div>
                        <div className="d-flex gap-2" style={{ position: 'absolute', top: window.innerWidth < 576 ? '290px' : '320px', right: window.innerWidth < 576 ? '15px' : '30px' }}>
                            <ButtonFill2 buttonName="Empowerment" widthCustom={window.innerWidth < 576 ? "130px" :"190px"} onClick={handleValueClick}/>
                            <ButtonFillWhite2 buttonName="Innovation" widthCustom={window.innerWidth < 576 ? "130px" :"190px"}  onClick={handleImpactClick}/>
                        </div>
                        <p>
                            Layanan yang dirancang untuk memfasilitasi pertumbuhan, pembelajaran, dan inovasi melalui berbagai program inspiratif.
                            Layanan ini bertujuan untuk menjadi pendorong utama dalam memicu perubahan positif di bidang proyek inovasi, membantu
                            peserta mengembangkan ide-ide baru dan membawa dampak nyata dalam industri mereka.
                        </p>
                    </div>
                </Col>
                <Col xs={12} xl={{ span: 6, offset: 1 }} data-aos="fade-up">
                    <Row>
                        {ServiceData.Collections.map((service, index) => {
                            
                            return (
                                <Col xl={6} key={service.id}>
                                    <div 
                                        className="service-card"
                                        style={{ 
                                            height: '237px', 
                                            width: '100%', 
                                            background: '#F4F5F7', 
                                            borderRadius: '8px', 
                                            padding: '20px', 
                                            marginBottom: window.innerWidth < 576 ? '20px' : (index === 4 || index === 5) ? '0' :'20px' }}
                                    >
                                        <div className="d-flex flex-column gap-2">
                                            <h6>0{service.id}</h6>
                                            <h5>{service.properties.title}</h5>
                                            <p>{service.properties.description}</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </Container>

    );

};

export default ServiceContent;