import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import { MissionData } from "../../assets/js/missionData";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const VisionMissionContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <div className="d-flex">
                    <div style={{ background: '#F4F5F7', borderRadius: '8px', padding: '50px 26px' }}>
                        <Col xs={12} xl={{ span: 10, offset: 1 }} data-aos="fade-up">
                            <h1>Kami menyelenggarakan program yang memfasilitasi pertumbuhan, pembelajaran, dan inovasi, menginspirasi perubahan positif dalam proyek inovasi.</h1>
                        </Col>
                        <div className={window.innerWidth < 576 ? "d-flex flex-column gap-4" : "d-flex gap-4"} style={{ marginTop: window.innerWidth < 576 ? '40px' : '60px' }}>
                            {MissionData.Collections.map((mission) => {
                                return (
                                    <div key={mission.id} style={{ background: '#FFFFFF', borderRadius: '8px', padding: '20px', flex: 1 }} data-aos="fade-up">
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <Image src={mission.properties.icon} style={{height: window.innerWidth < 576 ? '20px' : null}}/>
                                            </div>
                                            <h5>{mission.properties.title}</h5>
                                        </div>
                                        <p>{mission.properties.description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Row>
        </Container>

    );

};

export default VisionMissionContent;