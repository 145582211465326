import React, { useState } from "react";
import {
    Button,
    Image
} from "react-bootstrap";

import ArrowIcon from "../../assets/images/icons/arrow-icon-main-two.svg";

const ButtonFillWhite = ({ buttonName, widthCustom, paddingCustom, onClick }) => {

    const [isHovered, setIsHovered] = useState(false);

    return (

        <Button
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={
                {
                    backgroundColor: isHovered ? '#344F97' : '#FFFFFF',
                    border: isHovered ? '1px solid #344F97' : '1px solid #FFFFFF',
                    borderRadius: '8px',
                    padding: paddingCustom,
                    cursor: 'pointer',
                    width: widthCustom,
                    fontSize: window.innerWidth < 576 ? '16px' : '18px',
                    color: isHovered ? '#FFFFFF' : '#000000',
                    transition: '0.3s all ease-in-out',
                    fontWeight: '600'
                }
            }
        >
            <div className="d-flex gap-3 align-items-center justify-content-center">
                <div>
                    {buttonName}
                </div>
                <div>
                    <Image 
                        src={ArrowIcon} 
                        style={{
                            filter: isHovered ? 'brightness(0) invert(1)' : null,
                            transition: '0.3s all ease-in-out',
                        }}
                    />
                </div>
            </div>
        </Button>

    );

};

export default ButtonFillWhite;