import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import MediaOneImage from "../../assets/images/media/media-1.jpeg";
import MediaTwoImage from "../../assets/images/media/media-2.jpg";
import MediaThreeImage from "../../assets/images/media/media-3.png";
import MediaFourImage from "../../assets/images/media/media-4.png";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const MediaContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5} className={window.innerWidth < 576 ? "d-flex flex-column gap-2" : "d-flex flex-column gap-3"} data-aos="fade-up">
                    <div style={{ height: window.innerWidth < 576 ? '300px' : '330px', width: '100%', background: '#F4F5F7' }}>
                        <Image src={MediaOneImage} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
                    </div>
                    <div className="media-detail-one d-flex flex-column">
                        <h6>14 Juli 2024</h6>
                        <h5>FT UNNES students win Gold at Global Youth Innovators 2024</h5>
                        <p>
                            Engineering students from UNNES won the Gold Medal at the Global Youth Innovators Competition, organized by Exalter Students in July 2024.
                        </p>
                        <p
                            onClick={() => window.location.href = 'https://unnes.ac.id/ft/2024/07/17/ft-unnes-students-successfully-win-gold-medal-in-global-youth-innovators-competition-2024/'}
                            className="btn-detail mt-2"
                        >
                            Read More
                        </p>
                    </div>
                </Col>
                <Col xl={7} className="d-flex flex-column gap-4">
                    <Row className={window.innerWidth < 576 ? "media-detail-two mt-4" : "media-detail-two"} data-aos="fade-up">
                        <Col xs={12} xl={5}>
                            <div style={{ height: '172px', width: '100%', background: '#F4F5F7' }}>
                                <Image src={MediaTwoImage} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
                            </div>
                        </Col>
                        <Col xs={12} xl={7}>
                            <h6>24 Juli 2024</h6>
                            <h5>Mahasiswa UMS Buat Aplikasi Cegah Stunting, Raih Medali Emas di Kompetisi Tingkat Internasional</h5>
                            <p
                                onClick={() => window.location.href = 'https://wartaptm.id/mahasiswa-ums-buat-aplikasi-cegah-stunting-raih-medali-emas-di-kompetisi-tingkat-internasional/'}
                                className="btn-detail mt-2"
                            >
                                Read More
                            </p>
                        </Col>
                    </Row>
                    <Row className={window.innerWidth < 576 ? "media-detail-three mt-4" : "media-detail-three"} data-aos="fade-up">
                        <Col xs={12} xl={5}>
                            <div style={{ height: '172px', width: '100%', background: '#F4F5F7' }}>
                                <Image src={MediaThreeImage} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
                            </div>
                        </Col>
                        <Col xs={12} xl={7}>
                            <h6>20 Juli 2024</h6>
                            <h5>Peringkat II National Ideapaper Competition 2024 Exalter Students</h5>
                            <p
                                onClick={() => window.location.href = 'https://accounting.binus.ac.id/achievement/peringkat-ii-national-ideapaper-competition-2024-exalter-students/'}
                                className="btn-detail mt-2"
                            >
                                Read More
                            </p>
                        </Col>
                    </Row>
                    <Row className={window.innerWidth < 576 ? "media-detail-four mt-4" : "media-detail-four"} data-aos="fade-up">
                        <Col xs={12} xl={5}>
                            <div style={{ height: '172px', width: '100%', background: '#F4F5F7' }}>
                                <Image src={MediaFourImage} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
                            </div>
                        </Col>
                        <Col xs={12} xl={7}>
                            <h6>5 Agustus 2024</h6>
                            <h5>Juara 3: GYIC yang diselenggarakan oleh Exalter Students pada 10 Juni - 14 Juli 2024</h5>
                            <p
                                onClick={() => window.location.href = 'https://www.threads.net/@fia.ui/post/C-Rauy1ShXO'}
                                className="btn-detail mt-2"
                            >
                                Read More
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    );

};

export default MediaContent;