export const ImpactData = {
    "type": "Impact",
    "Collections": [
        {
            type: "Impact",
            id: "1",
            properties:
            {
                count: "15+",
                title: "Program inspiratif dan inovatif"
            }
        },
        {
            type: "Impact",
            id: "2",
            properties:
            {
                count: "35+",
                title: "Pembicara dan mentor berpengalaman"
            }
        },
        {
            type: "Impact",
            id: "3",
            properties:
            {
                count: "30+",
                title: "Universitas dan lembaga terkemuka"
            }
        }
    ]
};