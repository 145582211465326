import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
} from "react-bootstrap";

import MapWrapped from "../map/MapWrapped";

import "leaflet/dist/leaflet.css";
import "../../assets/css/style.css";

const FooterComponent = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#about-us-content';
        } else {
            document.getElementById('about-us-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleMediaClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#media-content';
        } else {
            document.getElementById('media-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handlePartnerClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#partner-content';
        } else {
            document.getElementById('partner-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleServiceClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#service-content';
        } else {
            document.getElementById('service-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <div style={{ backgroundColor: '#344F97', marginTop: window.innerWidth < 576 ? '60px' : '120px', paddingTop: '35px', paddingBottom: '30px', color: 'white' }}>
            <Container>
                <Row className="footer-quick-menu">
                    <Col xs={12} xl={4}>
                        <h1>Exalter Students</h1>
                        <MapWrapped
                            centerCoordinates={[-6.276903, 107.019376]}
                        />
                    </Col>
                    <Col xs={6} xl={2} className={window.innerWidth < 576 ? "mt-4" : null}>
                        <h6><strong>Company</strong></h6>
                        <p onClick={handleAboutUsClick}>Tentang Kami</p>
                        <p onClick={handleServiceClick}>Servis</p>
                        <p onClick={handlePartnerClick}>Partner</p>
                        <p onClick={() => window.location.href = `/events`}>Events</p>
                        <p onClick={handleMediaClick}>Media</p>
                    </Col>
                    <Col xs={6} xl={2} className={window.innerWidth < 576 ? "mt-4" : null}>
                        <h6><strong>Service</strong></h6>
                        <p>Incubation</p>
                        <p>Mentorship</p>
                        <p>Competition</p>
                        <p>Conference</p>
                        <p>Summit</p>
                        <p>Community</p>
                    </Col>
                    <Col xs={6} xl={2} className={window.innerWidth < 576 ? "mt-4" : null}>
                        <h6><strong>Impact</strong></h6>
                        <p>Events</p>
                        <p>Speakers & Mentors</p>
                        <p>Universities & Institution</p>
                        <p>Students</p>
                        <p>Achievements</p>
                    </Col>
                    <Col xs={6} xl={2} className={window.innerWidth < 576 ? "mt-4" : null}>
                        <h6><strong>Let's Connect</strong></h6>
                        <p onClick={() => window.location.href = `https://www.instagram.com/exalter_students/`}>Instagram</p>
                        <p onClick={() => window.location.href = `https://www.linkedin.com/company/exalter-students/`}>LinkedIn</p>
                    </Col>
                </Row>
                <div style={{marginTop: window.innerWidth < 576 ? '40px' : '60px', fontSize: window.innerWidth < 576 ? '16px' : '18px'}}>
                    <Row>
                        <Col xs={12} xl={12} className="d-flex justify-content-center">
                            <p className="text-center">© 2024 Inventify Center. All rights reserved.</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

    );

};

export default FooterComponent;