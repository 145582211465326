import InnovationIcon from "../images/icons/innovation.png";
import CollaborationIcon from "../images/icons/collaboration.png";
import AccelerationIcon from "../images/icons/acceleration.png";

export const ValueData = {
    "type": "Value",
    "Collections": [
        {
            type: "Value",
            id: "1",
            properties:
            {
                title: "Innovation",
                description: "Mendorong terciptanya keunggulan.",
                icon: InnovationIcon,
            }
        },
        {
            type: "Value",
            id: "2",
            properties:
            {
                title: "Collaboration",
                description: "Mendukung kerja sama mencapai tujuan bersama.",
                icon: CollaborationIcon,
            }
        },
        {
            type: "Value",
            id: "3",
            properties:
            {
                title: "Acceleration",
                description: "Mendorong pertumbuhan positif.",
                icon: AccelerationIcon,
            }
        }
    ]
};