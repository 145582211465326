import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import HighlightOneImage from "../../assets/images/general/gyic.jpg";
import HighlightTwoImage from "../../assets/images/general/maximal.jpg"

import { ImpactData } from "../../assets/js/impactData";
import ButtonFill from "../button/ButtonFill";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const ImpactContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={4} className="impact-detail">
                    <h1 data-aos="fade-up">Memberdayakan Generasi Inovatif</h1>
                    <p data-aos="fade-up">Mendukung dan mengembangkan potensi anak muda untuk menjadi pemimpin inovasi masa depan.</p>
                    <div className="d-flex flex-column impact-card">
                        {ImpactData.Collections.map((impact) => {
                            return (
                                <div
                                    key={impact.id}
                                    className="d-flex align-items-center justify-content-center"
                                    data-aos="fade-up"
                                    style={{ height: window.innerWidth < 576 ? '150px' : '230px', width: '100%', borderRadius: '8px', background: '#F4F5F7', textAlign: 'center' }}>
                                    <div className="d-flex flex-column gap-3">
                                        <h5>{impact.properties.count}</h5>
                                        <h6>{impact.properties.title}</h6>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Col>
                <Col xs={12} xl={8}>
                    <div className="d-flex flex-column impact-highlight">
                        <div style={{ height: window.innerWidth < 576 ? '100%' : '445px', width: '100%', borderRadius: '8px', background: '#F4F5F7', padding: '40px 30px', marginTop: window.innerWidth < 576 ? '20px' : null }}>
                            <Row className="d-flex align-items-center" data-aos="fade-up">
                                <Col xs={12} xl={6}>
                                    <h1>Inovasi untuk Masa Depan yang Lebih Cerah </h1>
                                    <p>
                                        AI-Prosthetic Arm yang dikembangkan membantu anak-anak disabilitas hidup lebih mandiri dengan
                                        teknologi AI yang mendeteksi dan mengendalikan gerakan, menciptakan alat yang fungsional dan ramah anak.
                                    </p>
                                    <ButtonFill
                                        buttonName="Lihat Detail"
                                        widthCustom= {window.innerWidth < 576 ? "150px" : "190px"}
                                        onClick={() => window.location.href = 'https://www.instagram.com/p/C-9WSf5PVWg/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=='}
                                    />
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div style={{ height: window.innerWidth < 576 ? '300px' : '370px', marginTop: window.innerWidth < 576 ? '20px' : null }}>
                                        <Image src={HighlightOneImage} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ height: window.innerWidth < 576 ? '100%' : '445px', width: '100%', borderRadius: '8px', background: '#F4F5F7', padding: '40px 30px' }}>
                            <Row className="d-flex align-items-center" data-aos="fade-up">
                                <Col xs={12} xl={6}>
                                    <div style={{ height: window.innerWidth < 576 ? '300px' : '370px', marginTop: window.innerWidth < 576 ? '20px' : null }}>
                                        <Image src={HighlightTwoImage} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6} style={{marginTop: window.innerWidth < 576 ? '20px' : null}}>
                                    <h1>Maksimalkan Sumber Daya Kampus untuk Sukses</h1>
                                    <p>
                                        Manfaatkan ruang kelas, perpustakaan, organisasi, layanan karier, dan konseling kampus untuk
                                        mendukung pencapaian akademik dan kariermu.
                                    </p>
                                    <ButtonFill
                                        buttonName="Lihat Detail"
                                        widthCustom= {window.innerWidth < 576 ? "150px" : "190px"}
                                        onClick={() => window.location.href = 'https://www.instagram.com/p/C_Frl-0SFnh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=='}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

    );

};

export default ImpactContent;