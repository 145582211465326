import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import { TestimoniData } from "../../assets/js/testimoniData";
import { Swiper, SwiperSlide } from 'swiper/react';

import PartnerClientContent from "../marquee/PartnerMarquee";

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const PartnerContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5} className="partner-highlight" data-aos="fade-up">
                    <h1>Dipercaya Oleh Lebih Dari 30+ Mitra dan Media</h1>
                    <p>Kami dipercaya oleh lebih dari 30 mitra dan media terkemuka, yang menggarisbawahi kepercayaan dan kredibilitas kami dalam menyediakan solusi inovatif dan berkualitas.</p>
                    <div className="partner-logo">
                        <PartnerClientContent />
                    </div>
                </Col>
                <Col xs={12} xl={{ span: 6, offset: 1 }} data-aos="fade-up">
                    <div style={{ height: '100%', width: '100%', borderRadius: '8px' }}>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                            style={{ height: '100%' }}
                        >
                            {TestimoniData.Collections.map((testimoni) => {
                                return (
                                    <SwiperSlide key={testimoni.id}>
                                        <div>
                                            <div className="d-flex gap-3 align-items-center profile">
                                                <div style={{ height: window.innerWidth < 576 ? '60px' : '80px', width: window.innerWidth < 576 ? '60px' : '80px', borderRadius: '50%' }}>
                                                    <Image src={testimoni.properties.icon}/>
                                                </div>
                                                <div>
                                                    <h5>{testimoni.properties.name}</h5>
                                                    <p>{testimoni.properties.employment}</p>
                                                </div>
                                            </div>
                                            <p>{testimoni.properties.text}</p>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </Col>
            </Row>
        </Container>

    );

};

export default PartnerContent;