import InnovationIcon from "../images/icons/mission-1.png";
import AccessibleIcon from "../images/icons/mission-2.png";
import SupportIcon from "../images/icons/mission-3.png";

export const MissionData = {
    "type": "Mission",
    "Collections": [
        {
            type: "Mission",
            id: "1",
            properties:
            {
                title: "Innovative Program",
                description: "Kami menyediakan program inovatif dan berkualitas tinggi yang mendukung pertumbuhan dan pengembangan proyek inovatif peserta.",
                icon: InnovationIcon,
            }
        },
        {
            type: "Mission",
            id: "2",
            properties:
            {
                title: "Acessible Service",
                description: "Kami menawarkan akses bagi peserta untuk memperluas pengetahuan, keterampilan, dan jaringan mereka dalam lingkungan yang kolaboratif dan mendukung.",
                icon: AccessibleIcon,
            }
        },
        {
            type: "Mission",
            id: "3",
            properties:
            {
                title: "Endless Support",
                description: "Kami mendukung dan memfasilitasi pengembangan proyek inovatif yang mempromosikan solusi kreatif untuk tantangan global.",
                icon: SupportIcon,
            }
        }
    ]
};