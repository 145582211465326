import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import { ValueData } from "../../assets/js/valueData";

import ValueOneImage from "../../assets/images/general/value-one.png";
import ValueTwoImage from "../../assets/images/general/value-two.png";

import ButtonFill from "../button/ButtonFill";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const ValueContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row className="d-flex align-items-stretch">
                <Col xs={12} xl={5} className="value-description">
                    <h1 data-aos="fade-up">Membangun Masa Depan dengan Kolaborasi, Akselerasi dan Inovasi</h1>
                    <p data-aos="fade-up">Nilai-nilai kami adalah dasar dari setiap keputusan dan tindakan, membimbing kami untuk bertindak dengan integritas, kolaborasi, inovasi, dan komitmen terhadap keunggulan.</p>
                    <div className="value-point d-flex flex-wrap gap-3" data-aos="fade-up">
                        {ValueData.Collections.map((value) => {
                            return (
                                <div
                                    key={value.id}
                                    className={window.innerWidth < 576 ? "px-3 py-2 d-flex align-items-center gap-3" : "px-4 py-4 d-flex align-items-center gap-4"}
                                    style={{ height: window.innerWidth < 576 ? '80px' : '100px', width: '100%', background: '#F4F5F7', borderRadius: '8px' }}
                                    data-aos="fade-up"
                                >
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <Image src={value.properties.icon} style={{height: window.innerWidth < 576 ? '20px' : null}}/>
                                    </div>
                                    <div>
                                        <h5>{value.properties.title}</h5>
                                        <p>{value.properties.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Col>
                <Col xs={12} xl={4} data-aos="fade-up">
                    <div style={{ height: '100%', width: '100%', borderRadius: '8px', marginTop: window.innerWidth < 576 ? '20px' : null }}>
                        <Image style={{ height: window.innerWidth < 576 ? '360px' : '100%', width: '100%' }} src={ValueOneImage} />
                    </div>
                </Col>
                <Col xs={12} xl={3} className="about-us d-flex flex-column" style={{ marginTop: window.innerWidth < 576 ? '20px' : null }}>
                    <h5 data-aos="fade-up">Exalter Students: Mendorong Inovasi Siswa</h5>
                    <p data-aos="fade-up">Exalter Students mendorong inovasi students melalui program inkubasi, workshop, kompetisi, dan konferensi.</p>
                    <div className="button" style={{ marginTop: window.innerWidth < 576 ? '0' : null }}>
                        <ButtonFill
                            onClick={() => window.location.href = `https://www.instagram.com/exalter_students/`}
                            buttonName="Tentang Kami"
                            widthCustom="100%"
                        />
                    </div>
                    <div style={{ marginTop: window.innerWidth < 576 ? '20px' : '35px', height: '100%', width: '100%', borderRadius: '8px' }}  data-aos="fade-up">
                        <Image style={{ height: window.innerWidth < 576 ? '360px' : '100%', width: '100%' }} src={ValueTwoImage} />
                    </div>
                </Col>
            </Row>
        </Container>

    );

};

export default ValueContent;