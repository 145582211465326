export const ServiceData = {
    "type": "Service",
    "Collections": [
        {
            type: "Service",
            id: "1",
            properties:
            {
                title: "Incubation",
                description: "Pelatihan dalam ideasi, pemecahan masalah, dan keterampilan penting lainnya untuk mempercepat proyek inovasi."
            }
        },
        {
            type: "Service",
            id: "2",
            properties:
            {
                title: "Mentorship",
                description: "Kesempatan mentoring antar-rekan bertujuan untuk mendorong kolaborasi dan berbagi pengetahuan di antara para peserta."
            }
        },
        {
            type: "Service",
            id: "3",
            properties:
            {
                title: "Competition",
                description: "Kompetisi nasional dan internasional yang diselenggarakan oleh ExalterStudents, sejalan dengan tantangan global."
            }
        },
        {
            type: "Service",
            id: "4",
            properties:
            {
                title: "Conference",
                description: "Menyatukan pemikir dari seluruh dunia untuk bertukar ide dan berkolaborasi melalui artikel-artikel unggulan mereka."
            }
        },
        {
            type: "Service",
            id: "5",
            properties:
            {
                title: "Summit",
                description: "Kesempatan bagi peserta untuk terhubung dengan pemimpin berpengaruh, pengubah permainan, dan organisasi, serta memperoleh wawasan dan bimbingan."
            }
        },
        {
            type: "Service",
            id: "6",
            properties:
            {
                title: "Community",
                description: "Berkolaborasi dengan sekolah, universitas, dan lembaga pendidikan untuk memberdayakan siswa dan mendorong keunggulan akademik."
            }
        }
    ]
};