import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import {
    Row,
    Col,
    Container,
    Form
} from "react-bootstrap";
import axios from "axios";
import AOS from "aos";

import ButtonFill from "../button/ButtonFill";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const ContactContent = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmitEmail = async (e) => {

        e.preventDefault();

        try {

            const response = await axios.post(
                'https://formspree.io/f/mblrbada',
                {
                    name: `${firstName} ${lastName}`,
                    phone,
                    email,
                    message
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            if (response) {
                enqueueSnackbar('Email sent successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 });
            }

        } catch (err) {
            enqueueSnackbar('An error occurred while sending the email', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 });
        }
    };


    const handleSendMessage = () => {

        const payloadMessage = {
            qeustion: 'Halo, saya tertarik dengan program yang tersedia di Exalter Students!'
        };

        const message = `${payloadMessage.qeustion}`;

        const whatsappUrl = `https://wa.me/6285214960974?text=${message}`;

        window.open(whatsappUrl, "_blank");

    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5} className="d-flex flex-column" data-aos="fade-up">
                    <div className="head-contact flex-grow-1">
                        <h1>Masukan anda membuat kami lebih baik</h1>
                        <p>Kami ada di sini untuk membantu anda dan ingin terhubung dengan anda.</p>
                        <p>Kami akan kembali dalam 24 jam.</p>
                    </div>
                    <div 
                        className="wa-contact d-flex flex-column gap-3 mt-auto" 
                        style={{ height: window.innerWidth < 576 ? '220px' : '210px', width: '100%', background: '#F4F5F7', borderRadius: '8px', padding: window.innerWidth < 576 ? '20px' : '20px 50px 0 20px' }}>
                        <div>
                            <h5>Lanjutkan Percakapan Bersama Kami di WhatsApp</h5>
                            <p>Jika Anda ingin diskusi lebih lanjut, hubungi kami langsung via WhatsApp. Klik tombol di bawah untuk terhubung.</p>
                        </div>
                        <ButtonFill onClick={handleSendMessage} buttonName="Mari Berbincang" widthCustom={window.innerWidth < 576 ? "160px" : "190px"} />
                    </div>
                </Col>
                <Col xs={12} xl={{ span: 6, offset: 1 }} data-aos="fade-up">
                    <div className="form-message">
                        <Form>
                            <Row className={window.innerWidth < 576 ? "mt-3" : "mt-2"}>
                                <Col xs={12} xl={6} data-aos="fade-up">
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Nama Depan</Form.Label>
                                        <Form.Control type="text" placeholder="Nama depanmu" style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setFirstName(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} xl={6} className={window.innerWidth < 576 ? "mt-3" : null} data-aos="fade-up">
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Nama Belakang</Form.Label>
                                        <Form.Control type="text" placeholder="Nama belakangmu" style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setLastName(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={window.innerWidth < 576 ? "mt-3" : "mt-2"} data-aos="fade-up">
                                <Col xs={12} xl={12}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Email</Form.Label>
                                        <Form.Control type="email" placeholder="name@example.com" style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setEmail(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={window.innerWidth < 576 ? "mt-3" : "mt-2"} data-aos="fade-up">
                                <Col xs={12} xl={12}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Nomor Telepon</Form.Label>
                                        <Form.Control type="text" placeholder="Nomor teleponmu" style={{ height: window.innerWidth < 576 ? '40px' : '50px' }} onChange={(e) => setPhone(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={window.innerWidth < 576 ? "mt-3" : "mt-2"} data-aos="fade-up">
                                <Col xs={12} xl={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label style={{ fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Pesan</Form.Label>
                                        <Form.Control as="textarea" rows={window.innerWidth < 576 ? '3' : '5'} onChange={(e) => setMessage(e.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} xl={12} data-aos="fade-up">
                                    <ButtonFill
                                        onClick={handleSubmitEmail}
                                        widthCustom="100%"
                                        buttonName="Kirim Pesan"
                                        heightCustom={window.innerWidth < 576 ? "45px" : "50px"}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>

    );

};

export default ContactContent;